interface Person {
  name: string;
  description: string;
}

export const Participants = () => {
  const people: Person[] = [
    {
      name: 'Małgorzata Biedrzycka',
      description: 'Zajmuje się dokumentacją firmy, obsługą strony internetowej i aplikacji (zmiany, korekty, aktualizacje, nowinki), sklepem internetowym jak również przygotowaniem owocu, sprzedażą wysyłkową, szukaniem odbiorców, przyjmowaniem zamówień'
    },
    {
      name: 'Konrad Herubiński',
      description: 'Zajmuje się przygotowaniem jakościowym owocu, ważeniem, dostarczaniem do siedziby grupy, sprzedażą bezpośrednią, zakupem opakowań, szukaniem odbiorców, przyjmowaniem zamówień'
    },
    {
      name: 'Erwina Różańska',
      description: 'Zajmuje się przygotowaniem jakościowym owocu, ważeniem, dostarczaniem do siedziby grupy, sprzedażą bezpośrednią, szukaniem odbiorców, przyjmowaniem zamówień'
    },
    {
      name: 'Bogumił Szeląg',
      description: 'Zajmuje się przygotowaniem jakościowym owocu, ważeniem, dostarczaniem do siedziby grupy, szukaniem odbiorców, przyjmowaniem zamówień'
    },
    {
      name: 'Alicja Herubińska',
      description: 'Zajmuje się przygotowaniem jakościowym owocu, ważeniem, dostarczaniem do siedziby grupy, sprzedażą bezpośrednią, szukaniem odbiorców, przyjmowaniem zamówień'
    },
  ];
  return (
    <div id='participants' className="container">
      <p className='title'>Członkowie grupy</p>
      <div className="columns is-mobile is-multiline is-centered">
        {people.map(person => (
          <div className='column is-one-third-desktop is-full-mobile'>
            <div className='box'>
              <p className='title'>{person.name}</p>
              <br />
              <p>{person.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
