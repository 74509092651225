import './UE.css';
export const UE = () => {
  return (
    <div className='container'>
      <div className='columns is-mobile is-multiline is-centered'>
        <div className='column'>
          <img src={require('../../img/symbol_UE_fix-100x67.jpg')} className='is-100x67'/>
        </div>
        <div className='column is-four-fifths-desktop is-full-mobile'>
          <p>Europejski Fundusz Rolny na rzecz Rozwoju Obszarów Wiejskich: Europa inwestująca w obszary wiejskie”. Operacja mająca na celu utworzenie krótkiego łańcucha dostaw współfinansowana jest ze środków Unii Europejskiej w ramach działania „Współpraca” Programu Rozwoju Obszarów Wiejskich na lata 2014-2020. Przewidywane wyniki operacji: sprzedaż produktów poprzez utworzony krótki łańcuch dostaw.</p>
        </div>
        <div className='column'>
          <img src={require('../../img/prow_logo_fix-100x67.jpg')} className='is-100x67'/>
        </div>
      </div>
    </div>
  );
}