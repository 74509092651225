interface Product {
  name: string;
  img: JSX.Element;
  description: string;
}

export const Offer = () => {
  const products: Product[] = [
    {
      name: 'Jabłko',
      img: <img src={require('../../img/apple.jpeg')} />,
      description: 'Produkt oferujemy od połowy września do połowy kwietnia. Towar pakowany w zależności od potrzeb klienta. Integrowana produkcja.',
    },
    {
      name: 'Gruszka',
      img: <img src={require('../../img/pear.jpeg')} />,
      description: 'Produkt oferujemy od połowy września do połowy kwietnia. Towar pakowany w zależności od potrzeb klienta. Integrowana produkcja.',
    },
    {
      name: 'Truskawka',
      img: <img src={require('../../img/strawberry.jpeg')} />,
      description: 'Uprawa tunelowa i w gruncie. Produkt oferujemy od połowy maja do połowy sierpnia. Towar pakowany w zależności od potrzeb klienta.',
    },
    {
      name: 'Wiśnia',
      img: <img src={require('../../img/cherry.jpeg')} />,
      description: 'Produkt oferujemy od połowy maja do połowy sierpnia. Towar pakowany w zależności od potrzeb klienta.'
    },
    {
      name: 'Czereśnia',
      img: <img src={require('../../img/gean.jpeg')} />,
      description: 'Produkt oferujemy od połowy maja do połowy sierpnia. Towar pakowany w zależności od potrzeb klienta.'
    },
    {
      name: 'Śliwka',
      img: <img src={require('../../img/plum.jpeg')} />,
      description: 'Produkt oferujemy od połowy maja do połowy sierpnia. Towar pakowany w zależności od potrzeb klienta.'
    }
  ];
  return (
    <div id="offer" className="container">
      <p className="title">Produkty</p>
      <p>Informacje o dostępnym asortymencie będą aktualizowane na bieżąco.</p>
      <div className="columns is-mobile is-multiline is-centered">
        {products.map(product => (
          <div className="column is-one-third-desktop is-full-mobile">
            <div className="column box">
              {product.img}
              <p className="subtitle">{product.name}</p>
              <p>{product.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
