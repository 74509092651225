import React from 'react';
import logo from './logo.svg';
import 'bulma/css/bulma.min.css';
import './App.css';
import { AppBar } from './components/appbar/AppBar';
import { Home } from './screens/home/Home';
import { About } from './screens/about/About';
import { Participants } from './screens/participants/Participants';
import { Offer } from './screens/offer/Offer';
import { UE } from './screens/ue/UE';

function App() {
  return (
    <>
      <AppBar />
      <Home />
      <About />
      <br />
      <Participants />
      <Offer />
      <UE />
    </>
  );
}

export default App;
