import './About.css';

export const About = () => {
  return (
    <div id='about' className='container'>
      <div className="tile is-ancestor">
        <div className="tile is-4 is-vertical is-parent is-hidden-mobile">
          <div className="tile is-child box">
            <img src={require('../../img/about_1.jpeg')} alt='O nas'/>
          </div>
        </div>
        <div className="tile is-parent">
          <div className="tile is-child box">
            <p className="title">O NAS</p>
            <br />
            <p className='subtitle'>Konsorcjum składa się z 5 członków i jej przewodniczącym jest Małgorzata Biedrzycka. Zostało założone 16 stycznia 2022 r.</p>
            <small>Kontakt: <a href='mailto:sad.witta@gmail.com'>sad.witta@gmail.com</a></small>
            <p>Nasze rodzinne gospodarstwa od wielu pokoleń specjalizują się w uprawie owoców deserowych.</p>
          </div>
        </div>
      </div>
      <div className="tile is-ancestor">
        <div className="tile is-vertical is-parent">
          <div className="tile is-child box">
            <p className="title">ZAŁOŻENIE I CEL POWSTANIA GRUPY</p>
            <br />
            <p className='subtitle'>Poprawa konkurencyjności producentów rolnych poprzez lepsze ich zintegrowanie z łańcuchem rolno-spożywczym poprzez systemy jakości, dodawanie wartości do produktów rolnych, promocję na rynkach lokalnych i krótkie cykle dostaw.</p>
          </div>
        </div>
        <div className="tile is-4 is-parent is-hidden-mobile">
          <div className="tile is-child box">
            <img src={require('../../img/about_2.jpeg')} alt='O nas'/>
          </div>
        </div>
      </div>
    </div>
  );
};
