import './Home.css';
export const Home = () => {
  return(
    <section id='home' className="hero medium has-background">
      <img alt="SAD Witta" className="hero-background is-transparent" src={require('../../img/intro.jpeg')} />
      <div className="hero-body has-text-centered">
        <img src={require('../../img/sad_witta.png')} alt='Sad Witta' width='256px'/>
        <p className="subtitle">
          Produkujemy dla was zdrową żywość, z pasją tworzymy owoce najwyższej jakości! Zapraszamy do zapoznania się z ofertą
        </p>
      </div>
    </section>
  )
}
