import { useState } from 'react';

export const AppBar = () => {
  const [menuOpened, setMenuOpened] = useState(false);
  return (
    <nav className="navbar is-fixed-top" role="navigation" aria-label="main navigation">
      <div className="navbar-brand">
        <a className="navbar-item" href="#">
          <img src={require('../../img/sad_witta_128.png')} alt="" />
          <span>&nbsp;SAD Witta</span>
        </a>

        <a role="button" className={`navbar-burger${menuOpened ? ' is-active' : ''}`} aria-label="menu"
           aria-expanded="false"
           data-target="navbarBasicExample" onClick={() => setMenuOpened(!menuOpened)}>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>

      <div id="navbarBasicExample" className={`navbar-menu${menuOpened ? ' is-active' : ''}`}>
        <div className="navbar-start">
          <a className="navbar-item" href="#about">O nas</a>
          <a className="navbar-item" href="#participants">Członkowie</a>
          <a className="navbar-item" href="#offer">Produkty</a>
          <a className="navbar-item" href={require('../../assets/załącznik_nr_4.xlsx')}>Załącznik</a>
        </div>
      </div>
    </nav>
  );
};
